import { useRouter } from 'next/router';

export type VolumeTemporality = '1h' | '1d' | '1w';

const useVolumeTemporality = (): VolumeTemporality => {
  const router = useRouter();
  const { subsection = '1h' } = router.query as { subsection: VolumeTemporality };
  return subsection;
};

export default useVolumeTemporality;
