import React from 'react';
import useSearchObjkts, { useSearchObjktsCount } from '~/hooks/useSearchObjkts';
import FetchMoreButton from './FetchMoreButton';
import ObjktFilterRow from './ObjktFilterRow';
import TokenList from './TokenList';

const LatestFeed = () => {
  const {
    status,
    tokens = [],
    fetchNextPage,
    isFetchingNextPage,
  } = useSearchObjkts();
  const { count, countLoading } = useSearchObjktsCount();
  const canFetchMore = tokens.length > 0 && tokens.length < count;
  return (
    <>
      <ObjktFilterRow count={ count } countLoading={ countLoading } />
      <TokenList tokens={ tokens } isLoading={ status === 'loading' } />
      {
        canFetchMore ? (
          <FetchMoreButton { ...{ fetchNextPage, isFetchingNextPage, enabled: canFetchMore } } />
        ) : null
      }
    </>
  );
};

export default LatestFeed;
