import axios from 'axios';
import { groupBy, orderBy, sumBy } from 'lodash';
import { useQuery } from 'react-query';
import { User } from '~/types';
import { ADDRESSES } from '~/utils/addresses';
import { DIVIDER } from '~/utils/const';
import useGraphqlClient from './useGraphqlClient';
import { getUsersData } from './useUsers';

export type Sponsor = {
  user: User;
  address: string;
  amount: number;
};

export const getSponsors = () => axios.get(`https://api.tzkt.io/v1/accounts/${ADDRESSES.hicaf}/operations`, {
  params: {
    type: 'transaction',
    status: 'applied',
    limit: 1000,
  },
});

const useSponsors = () => {
  const gqlClient = useGraphqlClient();
  return useQuery<Sponsor[]>(
    ['sponsors'],
    async () => {
      const { data: transactions = [] } = await getSponsors();
      const sponsors = orderBy(
        Object.entries(
          groupBy(transactions, (o) => o.sender.address),
        ).map(([address, txs]) => ({ address, amount: sumBy(txs, (o) => o.amount / DIVIDER) })),
        ['amount'],
        ['desc'],
      );
      const sponsorsAddresses = sponsors.map(({ address }) => address);
      const users = await getUsersData(sponsorsAddresses, gqlClient);
      return sponsors.map((sponsor) => ({
        ...sponsor,
        user: users.find(({ address }) => address === sponsor.address),
      })).filter(({ user }) => user);
    },
  );
};

export default useSponsors;
