/* eslint-disable max-len */
import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { motion, AnimateSharedLayout } from 'framer-motion';
import { Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { formatAmount, shortenAddress, xtz } from '~/utils';
import { getUserLink } from '~/graphql';
import Table from '~/components/Table';
// import ShowMoreButton from './ShowMoreButton';
// import { NftAsset } from './TokenAsset';
import { Operation } from '~/types';
import { NftAsset } from './TokenAsset';
import LiveFeedEmoji from './LiveFeedEmoji';
import StatusText from './StatusText';
import FlexRow from './FlexRow';
import _TzProfileBadge from './TzProfileBadge';
import UserTableItem from './UserTableItem';

const TzProfileBadge = styled(_TzProfileBadge)`
  position: relative;
  left: 5px;
  top: 2px;
`;

/*
const [limit, setLimit] = React.useState<number>(5);
const itemsLimited = items.slice(0, limit);
const canShowMore = items.length > limit;
const handleShowMore = () => setLimit(items.length);
*/

const NftAssetContainer = styled.div`
  display: block;
  width: 50px;
  height: 50px;
  flex-shrink: 0px;
  margin-right: 10px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.opacity};
`;

const StyledTr = styled(Tr)`
  td {
    padding: 15px;
    border-bottom: ${({ theme }) => theme.colors.borderXLight} solid thin;
  }
    @media (hover: hover) {
      &:hover {
        background-color: transparent;
        td {
          border-bottom-color: transparent;
        }
      }
    }
`;

const StyledTbody = styled(Tbody)``;

const LiveFeedTable: React.FC<{
  operations: Operation[];
}> = ({
  operations,
}) => (
  <>
    <Table>
      <Thead>
        <Tr>
          <Th>Type</Th>
          <Th>Objkt</Th>
          <Th>From</Th>
          <Th>To</Th>
          <Th style={ { textAlign: 'right' } }>Quantity</Th>
          <Th style={ { textAlign: 'right' } }>Price</Th>
          <Th style={ { textAlign: 'right' } }>Date</Th>
        </Tr>
      </Thead>
      <AnimateSharedLayout>
        <StyledTbody as={ motion.tbody } layout>
          {
            operations.map(({
              id,
              type,
              objkt,
              objktId,
              quantity,
              price,
              initiator,
              recipient,
              date,
            }) => (
              (
                <StyledTr as={ motion.tr } key={ `operation.${id}` }>
                  <Td style={ { minWidth: 150 } }>
                    <LiveFeedEmoji type={ type } />
                    <span style={ { marginLeft: 8 } }>
                      {
                        {
                          mint: 'Mint',
                          swap: 'Swap',
                          cancel_swap: 'Swap cancel',
                          burn: 'Burn',
                          collect: 'Collect',
                        }[type]
                      }
                    </span>
                  </Td>
                  <Td>
                    {
                      objkt ? (
                        <Link href={ `/o/${objkt.id}` } passHref>
                          <a>
                            <FlexRow>
                              <NftAssetContainer>
                                <NftAsset { ...objkt } />
                              </NftAssetContainer>
                              <div>
                                <span>{ objkt.title }</span>
                              </div>
                            </FlexRow>
                          </a>
                        </Link>
                      ) : objktId ? (
                        <Link href={ `/o/${objktId}` } passHref>
                          <a>
                            <NftAssetContainer />
                          </a>
                        </Link>
                      ) : null
                    }
                  </Td>
                  <Td>
                    <Link href={ getUserLink(initiator) } passHref>
                      <UserTableItem user={ initiator } />
                    </Link>
                  </Td>
                  <Td>
                    <Link href={ getUserLink(recipient) } passHref>
                      <a>
                        {recipient?.name || shortenAddress(recipient?.address) || '-'}
                      </a>
                    </Link>
                    <TzProfileBadge address={ recipient?.address } />
                  </Td>
                  <Td style={ { textAlign: 'right' } }>{quantity}</Td>
                  <Td style={ { textAlign: 'right' } }>
                    {price > 0 ? `${formatAmount(price, 2)} ${xtz}` : '-'}
                  </Td>
                  <Td style={ { textAlign: 'right' } }>{ date }</Td>
                </StyledTr>
              )
            ))
          }
        </StyledTbody>
      </AnimateSharedLayout>
    </Table>
    {
      operations.length === 0 ? (
        <StatusText style={ { padding: 15 } }>Nothing to show now. Waiting for incoming data...</StatusText>
      ) : null
    }
    {/*
        canShowMore ? (
          <ShowMoreButton onClick={ handleShowMore }>Show all activity</ShowMoreButton>
        ) : null
        */}
  </>
);
export default LiveFeedTable;

/*
import Link from 'next/link';
import styled from 'styled-components';
import { shortenAddress } from '~/utils';
import { media } from '~/styles/media';
import Emoji from './Emoji';
import { Objkt, User } from '~/types';

const ActivityList = styled.div`
  margin-top: 15px;
  a {
    display: block;
  }
`;

const OperationEmoji = styled(Emoji)`
  margin-right: 10px;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const OperationItem: React.FC<Operation> = (op) => (op.type === 'swap' ? (
  <Link href={ `/o/${op.objktId}` } passHref>
    <a>
      <OperationEmoji v="🎉" />
      {`SWAP: ${shortenAddress(op.initiatorAddress)} listed for sale ${op.quantity} edition${op.quantity > 1 ? 's' : ''} of objkt #${op.objktId} at ${op.price} tez. (${op.date})`}
    </a>
  </Link>
) : op.type === 'cancel_swap' ? (
  <Link href={ `/o/${op.objktId}` } passHref>
    <a>
      <OperationEmoji v="❌" />
      {`CANCEL SWAP: ${shortenAddress(op.initiatorAddress)} cancelled a swap on ${op.quantity} edition${op.quantity > 1 ? 's' : ''} of objkt #${op.objktId}. (${op.date})`}
    </a>
  </Link>
) : op.type === 'collect' ? (
  <Link href={ `/o/${op.objktId}` } passHref>
    <a>
      <OperationEmoji v="🤝" />
      {`COLLECT: ${shortenAddress(op.initiatorAddress)} purchased objtkt #${op.objktId} for ${op.price} tez. (${op.date})`}
    </a>
  </Link>
) : op.type === 'mint' ? (
  <Link href={ `/o/${op.objktId}` } passHref>
    <a>
      <OperationEmoji v="🎨" />
      {`MINT: ${shortenAddress(op.initiatorAddress)} minted objtkt #${op.objktId}. (${op.date})`}
    </a>
  </Link>
) : op.type === 'burn' ? (
  <Link href={ `/o/${op.objktId}` } passHref>
    <a>
      <OperationEmoji v="🔥" />
      {`BURN: ${shortenAddress(op.initiatorAddress)} burned ${op.quantity} edition${op.quantity > 1 ? 's' : ''} of objtkt #${op.objktId}. (${op.date})`}
    </a>
  </Link>
) : op.type === 'transfer' ? null : (
  <pre>{JSON.stringify(op, null, 2)}</pre>
));

      <ActivityList>
        {
          filteredFeed.map((op) => (
            <OperationItem key={ `op.${JSON.stringify(op)}` } { ...op } />
          ))
        }
      </ActivityList>
*/
