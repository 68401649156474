/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import omit from 'lodash/omit';
import qs from 'qs';
import Button from './Button';
import Checkbox from './Checkbox';
import Modal, { ModalHeader, ModalCloseButton, ModalBody } from '~/components/Modal';
import { defaultInitialValues } from '~/hooks/useSearchObjkts';
import { filterNonNull } from '~/utils';
import LiveFeedEmoji from './LiveFeedEmoji';
import { OperationType } from '~/types';
import Input from './Input';
import FlexRow from './FlexRow';

const StyledCheckbox = styled(Checkbox)`
  margin-right: 15px;
  margin-bottom: 5px;
  display: inline-block;
`;

const FilterContainer = styled.div`
  text-align: left;
`;

type OperationData = {
  label: string;
  value: OperationType;
};

const OPERATIONS_LIST: OperationData[] = [
  {
    label: 'Mint',
    value: 'mint',
  },
  {
    label: 'Swap',
    value: 'swap',
  },
  {
    label: 'Swap cancel',
    value: 'cancel_swap',
  },
  {
    label: 'Collect',
    value: 'collect',
  },
  {
    label: 'Burn',
    value: 'burn',
  },
];

export const useLiveFilterQueryParams = (): {
  activity: string[];
  wallet: string[];
} => {
  const router = useRouter();
  const {
    activity = '',
    wallet = '',
    ...query
  } = router.query;
  const activity_ = (activity as string).split(',').filter(Boolean);
  const wallet_ = (wallet as string).split(',').filter(Boolean);
  const result = {
    ...defaultInitialValues,
    ...query,
    activity: activity_,
    wallet: wallet_,
  };
  return result;
};

export const getNavUrl = (params, router) => {
  const path = router.asPath.split('?')[0];
  const query = filterNonNull(
    omit({
      ...router.query,
      ...params,
    }, [
      'name',
      'address',
      'section',
    ]),
  );
  return [path, qs.stringify(query)].filter(Boolean).join('?');
};

// eslint-disable-next-line no-underscore-dangle
export const setFilterQueryParams_ = (params, router) => {
  const url = getNavUrl(params, router);
  router.replace(url, url, { shallow: true });
};

export const useSetFilterQueryParams = () => {
  const router = useRouter();
  return (values) => setFilterQueryParams_(values, router);
};

const TypeRow = styled.div`
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 2px;
  }
`;

const OperationTypeItem: React.FC<OperationData> = ({ label, value }) => {
  const { activity } = useLiveFilterQueryParams();
  const setFilterQueryParams = useSetFilterQueryParams();
  const handleChange = () => setFilterQueryParams({
    activity: activity.includes(value)
      ? (activity as string[]).filter((m_) => m_ !== value).sort().join(',')
      : [...(activity as string[]), value].sort().join(','),
  });
  return (
    <TypeRow onClick={ handleChange }>
      <StyledCheckbox
        key={ `activity.${value}` }
        label={
          (
            <>
              <span style={ { marginLeft: 5, position: 'relative', top: 1 } }>
                <LiveFeedEmoji type={ value } />
              </span>
              <span style={ { marginLeft: 5 } }>{label}</span>
            </>
          )
        }
        value={ value }
        onChange={ handleChange }
        checked={ activity.includes(value) }
      />
    </TypeRow>
  );
};

const RemoveButton = styled.button.attrs({
  type: 'button',
})`
  all: unset;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const WalletsFilter = () => {
  const { wallet } = useLiveFilterQueryParams();
  const setFilterQueryParams = useSetFilterQueryParams();
  const addWallet = (value) => setFilterQueryParams({
    wallet: [...(wallet as string[]), value].sort().join(','),
  });
  const removeWallet = (value) => setFilterQueryParams({
    wallet: (wallet as string[]).filter((a_) => a_ !== value).sort().join(','),
  });
  const [addy, setAddy] = React.useState<string>('');
  const handleChange = (e) => setAddy(e.target.value);
  const handleSubmit = (e) => {
    e.preventDefault();
    addWallet(addy);
  };
  return (
    <>
      {
        wallet.map((a) => (
          <div key={ `live.filter.wallet.${a}` }>
            { a }
            {' '}
            (
            <RemoveButton onClick={ () => removeWallet(a) }>remove</RemoveButton>
            )
          </div>
        ))
      }
      <form onSubmit={ handleSubmit } style={ { marginTop: 10 } }>
        <FlexRow>
          <Input value={ addy } onChange={ handleChange } placeholder="Add a wallet" />
          <Button type="submit" style={ { marginLeft: 10 } }>Add</Button>
        </FlexRow>
      </form>
    </>
  );
};

const ActivityFilter = () => (
  <>
    {
      OPERATIONS_LIST.map((item) => (
        <OperationTypeItem key={ `type.${item.value}` } { ...item } />
      ))
    }
  </>
);

const LiveFilterButton: React.FC<{ className?: string; }> = ({ className }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const {
    activity,
    wallet,
  } = useLiveFilterQueryParams();
  const getFilterCount = (): number => {
    let count = 0;
    if (activity.length > 0) count += 1;
    if (wallet.length > 0) count += 1;
    return count;
  };
  const filterCount = getFilterCount();
  return (
    <>
      <Button className={ className } onClick={ () => setIsOpen(true) } style={ { marginLeft: 15 } }>
        {`Filter${filterCount ? ` (${filterCount})` : ''}`}
      </Button>
      <Modal isOpen={ isOpen } handleClose={ () => setIsOpen(false) }>
        <ModalHeader>
          <h2 style={ { margin: 0 } }>
            Filter activity
          </h2>
          <ModalCloseButton onClick={ () => setIsOpen(false) } />
        </ModalHeader>
        <ModalBody style={ { padding: 20, textAlign: 'center' } }>
          <FilterContainer>
            <div style={ { marginBottom: 10, fontWeight: 'bold' } }>Filter by activity type</div>
            <ActivityFilter />
            <div style={ { marginTop: 20, marginBottom: 10, fontWeight: 'bold' } }>Filter by wallet address</div>
            <WalletsFilter />
          </FilterContainer>
        </ModalBody>
      </Modal>
    </>
  );
};

export default LiveFilterButton;
