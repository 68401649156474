/* eslint-disable max-len */
// import Link from 'next/link';
import styled from 'styled-components';
import React from 'react';
import Modal, { ModalHeader, ModalCloseButton, ModalBody, ModalProps } from '~/components/Modal';
import useHicetnuncContext from '~/contexts/Hicetnunc/useHicetnuncContext';
import useOnNav from '~/hooks/useOnNav';
// import { DropdownSeparator } from './Dropdown';
import Emoji from './Emoji';
import Button from './Button';
import Input from './Input';
import useBatchOperation from '~/hooks/useBatchOperation';
import { TxConnect, TxError, TxMining, TxSuccess } from './TxModalContent';
import { xtz } from '~/utils';

const TextBold = styled.div`
  font-weight: bold;
`;

const DonationModal: React.FC<Omit<ModalProps, 'children'>> = ({
  isOpen,
  handleClose,
  keyPressEvents,
}) => {
  const [amount, setAmount] = React.useState<number>(10);
  const { acc, donate } = useHicetnuncContext();
  const { txStatus, txError, txHash, txRun } = useBatchOperation();
  useOnNav(handleClose);
  const handleDonate = async () => {
    await txRun(() => donate(+amount));
  };
  return (
    <Modal { ...{ isOpen, handleClose, keyPressEvents } }>
      <ModalHeader>
        <h2 style={ { margin: 0 } }>
          <TextBold>
            SPONSOR HIC.AF
            {' '}
            <Emoji v="🙌" />
          </TextBold>
        </h2>
        <ModalCloseButton onClick={ handleClose } />
      </ModalHeader>
      <ModalBody style={ { padding: 20, textAlign: 'center' } }>
        {
          !acc ? (
            <TxConnect />
          ) : txStatus === 'idle' || txStatus === 'pending' ? (
            <>
              <div>
                {`Love using HIC.AF? Send some love through a ${xtz} donation. `}
                <Emoji v="🥰" />
              </div>
              <div>
                <Input
                  type="number"
                  step={ 1 }
                  min={ 1 }
                  value={ amount }
                  onChange={ (e) => setAmount(+e.target.value) }
                  style={ { marginTop: 10, marginBottom: 10, width: 80 } }
                />
              </div>
              <Button $primary onClick={ handleDonate } disabled={ txStatus === 'pending' }>
                {txStatus === 'pending' ? 'Sending, hold on...' : 'Send your donation'}
              </Button>
            </>
          ) : txStatus === 'mining' ? (
            <TxMining txHash={ txHash } />
          ) : txStatus === 'success' ? (
            <TxSuccess txHash={ txHash } handleClick={ handleClose } message="Wow, we got you donation! Thanks a lot!!" />
          ) : txStatus === 'error' ? (
            <TxError txError={ txError } handleClick={ handleClose } />
          ) : null
        }
      </ModalBody>
    </Modal>
  );
};

export default DonationModal;
