/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import styled from 'styled-components';
import React from 'react';
import { useRouter } from 'next/router';
import { useDataContext } from '~/contexts/Data';
import { inputCss } from './Input';
import Modal, { ModalHeader, ModalCloseButton, ModalBody, ModalProps } from '~/components/Modal';
import TextTruncated from './TextTruncated';
import { DropdownItem } from './Dropdown';
import useTheme from '~/hooks/useTheme';
import { MenuContainer, MenuLink } from './Menu';
import { tezosAddressRegex } from '~/utils';
import FlexRow from './FlexRow';
import useUsers from '~/hooks/useUsers';

const Textarea = styled.textarea.attrs({
  spellCheck: false,
  autoCorrect: 'none',
  autoCapitalize: 'none',
})`
  ${inputCss}
  height: 150px;
`;

const jsonToCsv = (data: any[]) => {
  const fields = Object.keys(data[0]);
  const replacer = (key, value) => (value === null ? '' : value);
  const csv = data.map((row) => fields.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','));
  csv.unshift(fields.join(','));
  return csv.join('\r\n');
};

const downloadCsv = (data: (string | number)[], name: string) => {
  const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(jsonToCsv(data))}`;
  const downloadLink = document.createElement('a');
  downloadLink.href = csvContent;
  downloadLink.download = `${name}.csv`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

const downloadJson = (data: (string | number)[], name: string) => {
  const jsonContent = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(data, null, 2))}`;
  const downloadLink = document.createElement('a');
  downloadLink.href = jsonContent;
  downloadLink.download = `${name}.json`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

type Sections = 'followed' | 'favorites';

const DataExportButton: React.FC<{
  type?: Sections;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render?: (fn: any) => any;
}> = ({
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  render = () => null,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>();
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  return (
    <>
      <DropdownItem onClick={ handleOpen }>Import & export</DropdownItem>
      <DataExportModal isOpen={ isOpen } handleClose={ handleClose } />
    </>
  );
};

export default DataExportButton;

const DataExportModal: React.FC<Omit<ModalProps, 'children'>> = ({
  isOpen,
  handleClose,
  keyPressEvents,
}) => {
  const {
    favorites = [],
    followed = [],
  } = useDataContext();
  const { data: followedData } = useUsers(followed);
  const favoritesData = favorites.map((objktId) => ({
    objktId,
  }));
  const router = useRouter();
  const path = router.asPath.split('?')[0].split('/') as [null, Sections];
  const section_ = (['followed', 'favorites'].includes(path[1]) ? path[1] : 'followed') as Sections;
  const [section, setSection] = React.useState<Sections>(section_);
  React.useEffect(() => {
    setSection(section_);
  }, [section_]);
  const { setFavorites, setFollowed } = useDataContext();
  return (
    <Modal { ...{ isOpen, handleClose, keyPressEvents } }>
      <ModalHeader>
        <TextTruncated as="h2" style={ { margin: 0 } }>
          Import & export data
        </TextTruncated>
        <ModalCloseButton onClick={ handleClose } />
      </ModalHeader>
      <ModalBody style={ { padding: 20 } }>
        <MenuContainer style={ { marginTop: 0, marginBottom: 20 } }>
          <MenuLink
            $active={ section === 'followed' }
            onClick={
              (e) => {
                e.stopPropagation();
                setSection('followed');
              }
            }
          >
            Followed
          </MenuLink>
          <MenuLink
            $active={ section === 'favorites' }
            onClick={
              (e) => {
                e.stopPropagation();
                setSection('favorites');
              }
            }
          >
            Favorites
          </MenuLink>
        </MenuContainer>
        {
          section === 'followed' ? (
            <DataExportSection
              label="Followed artists addresses"
              fileName="hicaf-follows"
              values={ followed }
              valueRegex={ tezosAddressRegex }
              exportData={ followedData || followed }
              transformData={ (x) => x.trim() }
              handleSave={ setFollowed }
              editLabel={
                (
                  <>
                    To import, paste a list of artist wallet addresses above, with one per line. Make sure to
                    {' '}
                    <a
                      onClick={ () => downloadCsv(followed, 'hicaf-follows') }
                      style={ { fontWeight: 'bold' } }
                    >
                      download your data
                    </a>
                    {' '}
                    first in case you make a mistake.
                  </>
                )
              }
            />
          ) : section === 'favorites' ? (
            <DataExportSection
              label="Favorite objkts ids"
              fileName="hicaf-favorites"
              values={ favorites }
              valueRegex={ /\d+/g }
              exportData={ favoritesData }
              transformData={ (x) => +(x.trim()) }
              handleSave={ setFavorites }
              editLabel={
                (
                  <>
                    To import, paste a list of objkt ids above, with one per line. Make sure to
                    {' '}
                    <a
                      onClick={ () => downloadCsv(favorites, 'hicaf-favorites') }
                      style={ { fontWeight: 'bold' } }
                    >
                      download your data
                    </a>
                    {' '}
                    first in case you make a mistake.
                  </>
                )
              }
            />
          ) : null
        }
      </ModalBody>
    </Modal>

  );
};

const DataExportSection: React.FC<{
  label: string;
  fileName: string;
  editLabel: string | React.ReactNode;
  values: string[] | number[];
  valueRegex: RegExp;
  exportData: any;
  transformData: (x: string) => (string | number);
  handleSave: (data: (string | number)[]) => void;
}> = ({
  label,
  fileName,
  editLabel,
  values,
  valueRegex,
  exportData,
  transformData,
  handleSave,
}) => {
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [valuesInner, setValuesInner] = React.useState<string>(values.join('\n'));
  React.useEffect(() => {
    setValuesInner(values.join('\n'));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, JSON.stringify(values)]);
  const handleChange = (e) => {
    if (isEditing) {
      setValuesInner(e.target.value);
    }
  };
  const theme = useTheme();
  const realValues = isEditing ? valuesInner : values.join('\n');
  const handleCancelEditing = () => setIsEditing(false);
  const handleConfirmEditing = () => {
    if (window.confirm('You are about to edit your data. It is irreversible. Are you sure ?')) {
      const matches = valuesInner.match(valueRegex) || [];
      const values_ = matches.map(transformData);
      handleSave(values_);
      setIsEditing(false);
    }
  };
  return (
    <>
      <FlexRow style={ { marginBottom: 10 } }>
        <div style={ { flex: 1 } }>
          { label }
        </div>
        {
          isEditing ? null : (
            <>
              <a onClick={ () => setIsEditing(true) }>EDIT</a>
              <span style={ { margin: '0 8px' } }>|</span>
              <a onClick={ () => downloadJson(exportData, fileName) }>JSON</a>
              <span style={ { margin: '0 8px' } }>|</span>
              <a onClick={ () => downloadCsv(exportData, fileName) }>CSV</a>
            </>
          )
        }
      </FlexRow>
      <Textarea
        value={ realValues }
        onChange={ handleChange }
        onClick={ (e) => e.stopPropagation() }
      />
      {
        isEditing ? (
          <>
            <div style={ { marginBottom: 10 } }>{ editLabel }</div>
            <FlexRow style={ { justifyContent: 'flex-end' } }>
              <a
                onClick={ handleCancelEditing }
                style={ { marginLeft: 15 } }
              >
                CANCEL
              </a>
              <a
                onClick={ handleConfirmEditing }
                style={
                  {
                    marginLeft: 15,
                    color: theme.colors.primary,
                    fontWeight: 'bold',
                  }
                }
              >
                CONFIRM EDIT
              </a>
            </FlexRow>
          </>
        ) : null
      }
    </>
  );
};
