import { subDays, subHours, subWeeks } from 'date-fns';
import { gql } from 'graphql-request';
import { groupBy, orderBy, sumBy } from 'lodash';
import { useQuery } from 'react-query';
import React from 'react';
import { Trade } from '~/types';
import { DIVIDER } from '~/utils/const';
import useObjkts from '~/hooks/useObjkts';
import useUsers from '~/hooks/useUsers';
import useGraphqlClient from './useGraphqlClient';

const BestSellersQuery = gql`
    query BestSellers($after: timestamptz) {
        trade(
            where: {
                timestamp: {
                    _gte: $after
                }
            }
        ) {
            amount
            swap {
              price
              token_id
            }
            token {
              creator_id
              token_tags {
                tag {
                  tag
                }
              }
            }
            buyer {
              address
            }
        }
    }
`;

type BestSellingObjktRow = {
  objktId: string;
  volume: number;
};

type BestSellingArtistRow = {
  userAddress: string;
  volume: number;
};

type LargestCollectorsRow = {
  userAddress: string;
  volume: number;
};

type BestSellingTagRow = {
  tag: string;
  volume: number;
};

const getObjktVolumes = (trades: Trade[]) => orderBy(
  Object.entries<Trade[]>(
    groupBy(trades, (o) => o.swap.token_id),
  ).map(([objktId, tokenTrades]) => ({
    objktId,
    volume: sumBy(tokenTrades, (o) => o.swap.price) / DIVIDER,
  })),
  ['volume'],
  ['desc'],
);

const getArtistVolumes = (trades: Trade[]) => orderBy(
  Object.entries<Trade[]>(
    groupBy(trades, (o) => o.token.creator_id),
  ).map(([userAddress, tokenTrades]) => ({
    userAddress,
    volume: sumBy(tokenTrades, (o) => o.swap.price) / DIVIDER,
  })),
  ['volume'],
  ['desc'],
);

const getCollectorVolumes = (trades: Trade[]) => orderBy(
  Object.entries<Trade[]>(
    groupBy(trades, (o) => o.buyer.address),
  ).map(([userAddress, tokenTrades]) => ({
    userAddress,
    volume: sumBy(tokenTrades, (o) => o.swap.price) / DIVIDER,
  })),
  ['volume'],
  ['desc'],
);

const getTagVolumes = (trades: Trade[]) => orderBy(
  Object.entries<Trade[]>(
    trades.reduce((acc, trade) => {
      let res = { ...acc };
      const tags = trade.token.token_tags?.map(({ tag }) => tag.tag).filter(Boolean) || [];
      for (let i = 0; i < tags.length; i += 1) {
        const tag = tags[i];
        res = {
          ...res,
          [tag]: [
            ...(res[tag] || []),
            trade,
          ],
        };
      }
      return res;
    }, {}),
  ).map(([tag, tokenTrades]) => ({
    tag,
    volume: sumBy(tokenTrades, (o) => o.swap.price) / DIVIDER,
  })),
  ['volume'],
  ['desc'],
);

const useBestSellers = (after: string) => {
  const gqlClient = useGraphqlClient();
  return useQuery<{
    objkts: BestSellingObjktRow[];
    artists: BestSellingArtistRow[];
    collectors: LargestCollectorsRow[];
    tags: BestSellingTagRow[];
  }>(
    ['best.sellers', after],
    async () => {
      const { trade: trades = [] } = await gqlClient<{ trade: Trade[] }>(
        BestSellersQuery,
        { after },
      );
      return {
        objkts: getObjktVolumes(trades),
        artists: getArtistVolumes(trades),
        collectors: getCollectorVolumes(trades),
        tags: getTagVolumes(trades),
      };
    },
    { enabled: !!after },
  );
};

export default useBestSellers;

export const useBestSellersData = (duration: '1h' | '1d' | '1w', limit: number) => {
  const after = React.useMemo(() => {
    if (duration === '1h') return new Date(subHours(new Date(), 1)).toISOString();
    if (duration === '1d') return new Date(subDays(new Date(), 1)).toISOString();
    if (duration === '1w') return new Date(subWeeks(new Date(), 1)).toISOString();
    return null;
  }, [duration]);
  const { data: bestSellers, isLoading: isLoadingVolumes } = useBestSellers(after);
  const {
    objkts: bestSellingObjkts = [],
    artists: bestSellingArtists = [],
    collectors: largestCollectors = [],
    tags = [],
  } = bestSellers || {};
  const objktIds = bestSellingObjkts.map(({ objktId }) => objktId).slice(0, limit);
  const { isLoading: isLoadingObjkts, data: objkts = [], ...objktsQuery } = useObjkts(objktIds);
  const artistsIds = bestSellingArtists.map(({ userAddress }) => userAddress).slice(0, limit);
  const { isLoading: isLoadingArtists, data: artists = [] } = useUsers(artistsIds);
  const collectorsIds = largestCollectors.map(({ userAddress }) => userAddress).slice(0, limit);
  const { isLoading: isLoadingCollectors, data: collectors = [] } = useUsers(collectorsIds);
  const data = {
    objkts: bestSellingObjkts.map(({ objktId, volume }) => ({
      ...objkts.find(({ id }) => id === +objktId),
      volume,
    })).filter(({ id }) => !!id),
    artists: bestSellingArtists.map(({ userAddress, volume }) => ({
      ...artists.find(({ address }) => address === userAddress),
      volume,
    })),
    collectors: largestCollectors.map(({ userAddress, volume }) => ({
      ...collectors.find(({ address }) => address === userAddress),
      volume,
    })),
    tags,
  };
  return {
    ...objktsQuery,
    isLoading: isLoadingVolumes || isLoadingObjkts || isLoadingArtists || isLoadingCollectors,
    data,
  };
};
