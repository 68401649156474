import styled from 'styled-components';

const TextButton = styled.a.attrs({
  type: 'button',
})`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
`;

export default TextButton;
