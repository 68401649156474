import React from 'react';
import TokenList from './TokenList';
import useSearchObjkts, { useSearchObjktsCount } from '~/hooks/useSearchObjkts';
import ObjktFilterRow from './ObjktFilterRow';
import FetchMoreButton from './FetchMoreButton';

type Props = { addresses: string[]; rpp?: number; };

const CreatorsFeed: React.FC<Props> = ({
  addresses = [],
  rpp: limit = 20,
}) => {
  const {
    status,
    tokens = [],
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = useSearchObjkts({
    creators: addresses,
    limit,
  });
  const { count, countLoading } = useSearchObjktsCount({
    creators: addresses,
    limit,
  });
  const canFetchMore = tokens.length > 0 && tokens.length < count;
  React.useEffect(() => {
    refetch();
  }, [JSON.stringify(addresses)]);
  return (
    <>
      <ObjktFilterRow count={ count } countLoading={ countLoading } />
      <TokenList tokens={ tokens } isLoading={ status === 'loading' } />
      {
        canFetchMore ? (
          <FetchMoreButton { ...{ fetchNextPage, isFetchingNextPage } } />
        ) : null
      }
    </>
  );
};

export default CreatorsFeed;
