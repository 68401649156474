/* eslint-disable no-underscore-dangle */
import React from 'react';
import TokenList from './TokenList';
import FetchMoreButton from './FetchMoreButton';
import ObjktFilterRow from './ObjktFilterRow';
import useSearchObjkts, { useSearchObjktsCount } from '~/hooks/useSearchObjkts';

type Props = {
  ids: string[];
};

const FavoritesFeed: React.FC<Props> = ({
  ids: objktIds = [],
}) => {
  const {
    status,
    tokens = [],
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = useSearchObjkts({
    objktIds,
  });
  const {
    count,
    countLoading,
  } = useSearchObjktsCount({
    objktIds,
  });
  const canFetchMore = tokens.length > 0 && tokens.length < count;
  React.useEffect(() => {
    refetch();
  }, [JSON.stringify(objktIds)]);
  return (
    <>
      <ObjktFilterRow count={ count } countLoading={ countLoading } />
      <TokenList tokens={ tokens } isLoading={ status === 'loading' } />
      {
        canFetchMore ? (
          <FetchMoreButton { ...{ fetchNextPage, isFetchingNextPage } } />
        ) : null
      }
    </>
  );
};

export default FavoritesFeed;
