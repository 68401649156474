/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useInfiniteQuery, useQuery } from 'react-query';
import { gql } from 'graphql-request';
import styled from 'styled-components';
import React from 'react';
import { sumBy } from 'lodash';
import { calendar } from '~/hooks/useTimeAgo';
import CreatorsFeed from '~/components/CreatorsFeed';
import FavoritesFeed from '~/components/FavoritesFeed';
import LatestFeed from '~/components/LatestFeed';
import { useDataContext, useFavorites, useFollowed } from '~/contexts/Data';
import UserItem from '~/components/UserItem';
import { getItemsFromQuery, getNextPageParam, useSearchObjktsCount } from '~/hooks/useSearchObjkts';
import FetchMoreButton from '~/components/FetchMoreButton';
import { CountContainer, RightSection } from '~/components/ObjktFilterRow';
import { formatAmount, shortenAddress, xtz } from '~/utils';
import DataExportButton from '~/components/DataExportButton';
import LiveFeed from '~/components/LiveFeed';
import StatusText from '~/components/StatusText';
import LoadIcon from '~/components/LoadIcon';
import { User } from '~/types';
import { useBestSellersData } from '~/hooks/useBestSellers';
import TokenList from '~/components/TokenList';
import useVolumeTemporality from '~/hooks/useVolumeTemporality';
import FlexRow from '~/components/FlexRow';
import TextLight from '~/components/TextLight';
import UserAvatar from '~/components/UserAvatar';
import { getUserLink } from '~/graphql';
import TzProfileBadge from '~/components/TzProfileBadge';
import UserPopover from '~/components/UserPopover';
import { media } from '~/styles/media';
import LoaderStyle from '~/styles/Loader';
import { AvatarContainer } from '~/components/Avatar';
import TextLoader from '~/components/TextLoader';
import ObjktGridSelect from '~/components/ObjktGridSelect';
import useSponsors, { Sponsor } from '~/hooks/useSponsors';
import Emoji from '~/components/Emoji';
import DonationModal from '~/components/DonationModal';
import TextButton from '~/components/TextButton';
import useShowMore from '~/hooks/useShowMore';
import ShowMoreButton from '~/components/ShowMoreButton';

import { BreakpointValues } from '~/hooks/useBreakpoint';
import Layout from '~/components/Layout';
import useGraphqlClient from '~/hooks/useGraphqlClient';
import MenuNav from '~/components/MenuNav';

type PageParams = {
  section: 'followed' | 'favorites' | 'live' | 'best-sellers' | 'sponsors' | 'all';
};

const useSection = () => {
  const router = useRouter();
  const path = router.asPath.split('?')[0];
  if (path.startsWith('/best-sellers')) return 'best-sellers';
  if (path.startsWith('/favorites')) return 'favorites';
  if (path.startsWith('/followed')) return 'followed';
  if (path.startsWith('/sponsors')) return 'sponsors';
  if (path.startsWith('/live')) return 'live';
  if (path.startsWith('/all')) return 'all';
  const { section } = router.query as PageParams;
  return section;
};

const FeedMenu = () => {
  const section = useSection();
  return (
    <MenuNav
      label="Section"
      sections={
        [
          {
            label: 'Best sellers',
            url: '/',
            isActive: !section || section === 'best-sellers',
          },
          {
            label: 'Sponsors',
            url: '/sponsors',
            isActive: section === 'sponsors',
          },
          {
            label: 'All',
            url: '/all',
            isActive: section === 'all',
          },
          {
            label: 'Live',
            url: '/live',
            isActive: section === 'live',
          },
          {
            label: 'Followed',
            url: '/followed',
            isActive: section === 'followed',
          },
          {
            label: 'Favorites',
            url: '/favorites',
            isActive: section === 'favorites',
          },
        ]
      }
    />
  );
};

const Live = () => <LiveFeed />;

const Favorites = () => {
  const [favorites = []] = useFavorites();
  return (
    <>
      {/*
      <ExportText />
      */}
      {
        favorites.length === 0 ? (
          <StatusText style={ { marginTop: 15 } }>Please favorite some objkts first</StatusText>
        ) : <FavoritesFeed ids={ favorites } />
      }
    </>
  );
};

const FollowedObjkts = () => {
  const { followed = [] } = useDataContext();
  return <CreatorsFeed addresses={ followed } />;
};

const userFragment = `
  name
  description
  address
  metadata
  metadata_file
`;

const FollowedArtistsSearchQuery = gql`
  query FollowedArtistsSearchQuery(
    $addresses: [String] = [],
    $limit: Int,
    $offset: Int,
  ) {
    users: holder(
      where: {
        address: {
          _in: $addresses
        }
      },
      limit: $limit,
      offset: $offset
    ) {
      ${userFragment}
    }
  }
`;

const FollowedArtistsSearchCountQuery = gql`
  query FollowedArtistsSearchCountQuery(
    $addresses: [String] = [],
  ) {
    holder_aggregate(
      where: {
        address: {
          _in: $addresses
        }
      },
    ) {
      aggregate {
        count
      }
    }
  }
`;

const useFollowedSearch = (followed: string[]) => {
  const gqlClient = useGraphqlClient();
  const query = useInfiniteQuery<User[]>(
    ['users', ...followed.sort()],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async ({ pageParam: offset }) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { users = [] } = await gqlClient<{ users: User[] }>(
        FollowedArtistsSearchQuery,
        {
          addresses: followed,
          offset,
        },
      );
      return users;
    },
    {
      enabled: followed.length > 0,
      getNextPageParam,
    },
  );
  const artists = getItemsFromQuery<User>(query);
  return { ...query, artists };
};

const useFollowedSearchCount = (followed: string[]) => {
  const gqlClient = useGraphqlClient();
  const query = useQuery<number>(
    ['users.count', ...followed.sort()],
    async () => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { holder_aggregate } = await gqlClient(
        FollowedArtistsSearchCountQuery,
        {
          addresses: followed,
        },
      );
      return holder_aggregate?.aggregate?.count ?? 0;
    },
    {
      enabled: followed.length > 0,
    },
  );
  return {
    ...query,
    count: query.data,
    countLoading: query.status === 'loading',
  };
};

const StyledCountContainer = styled(CountContainer)`
  padding: 5px 0;
`;

const FollowedArtists = () => {
  const [followed] = useFollowed();
  const {
    status,
    artists = [],
    fetchNextPage,
    isFetchingNextPage,
    refetch: refetchList,
  } = useFollowedSearch(followed);
  const {
    count,
    status: countStatus,
    refetch: refetchCount,
  } = useFollowedSearchCount(followed);
  React.useEffect(() => {
    refetchList();
    refetchCount();
  }, [JSON.stringify(followed)]);
  const canFetchMore = artists.length > 0 && artists.length < count;
  return (
    <div style={ { marginTop: 15 } }>
      {
        status === 'loading'
          ? <LoadIcon $animating />
          : status === 'error'
            ? <StatusText>Something went wrong</StatusText>
            : artists?.length === 0 ? <StatusText>Nothing found</StatusText> : (
              <>
                <StyledCountContainer>
                  { countStatus === 'loading' ? 'Loading count...' : `${formatAmount(count, 0)} artists followed` }
                </StyledCountContainer>
                {
                  artists.map((user) => (
                    <UserItem key={ `user.${user.address}` } { ...user } />
                  ))
                }
                {
                  canFetchMore ? (
                    <FetchMoreButton { ...{ fetchNextPage, isFetchingNextPage } } />
                  ) : null
                }
              </>
            )
      }
    </div>
  );
};

const Banner = styled.div`
  background-color: ${({ theme }) => theme.colors.opacity};
  padding: 10px;
  margin-top: 15px;
`;

export const ExportText = () => (
  <Banner>
    This data is stored locally in your browser for now. You can
    {' '}
    <DataExportButton render={
      (handleClick) => (
        <a
          role="button"
          tabIndex={ 0 }
          onClick={ handleClick }
          onKeyPress={ () => null }
          style={ { fontWeight: 'bold' } }
        >
          import / export it
        </a>
      )
    }
    />
    {' '}
    if you want.
  </Banner>
);

const FollowedMenuNav = styled(MenuNav)`
  margin-top: 15px;
`;

const Followed = () => {
  const router = useRouter();
  const section = router.asPath === '/followed/artists' ? 'artists' : 'objkts';
  const { followed = [] } = useDataContext();
  const {
    count: objktCount,
    refetch: refetchObjktCount,
  } = useSearchObjktsCount({
    creators: followed,
  });
  const {
    count: artistCount,
    refetch: refetchArtistCount,
  } = useFollowedSearchCount(followed);
  React.useEffect(() => {
    refetchObjktCount();
    refetchArtistCount();
  }, [JSON.stringify(followed.sort())]);
  return followed.length === 0 ? (
    <StatusText style={ { marginTop: 15 } }>Please follow some artists first</StatusText>
  ) : (
    <>
      {/*
      <ExportText />
      */}
      <FollowedMenuNav
        // label="Followed entity"
        sections={
          [
            {
              label: `Objkts (${formatAmount(objktCount)})`,
              url: '/followed',
              isActive: section === 'objkts',
            },
            {
              label: `Artists (${formatAmount(artistCount)})`,
              url: '/followed/artists',
              isActive: section === 'artists',
            },
          ]
        }
      />
      {
        section === 'artists' ? (
          <FollowedArtists />
        ) : (
          <FollowedObjkts />
        )
      }
    </>
  );
};

const Title = styled.h2`
  margin-bottom: 15px;
`;

const ArtistGrid = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(2, 1fr);
  ${media.tablet`
    grid-template-columns: repeat(2, 1fr);  
  `}
  ${media.mobile`
    grid-template-columns: repeat(1, 1fr);  
  `}
`;

const SectionRow = styled(FlexRow)`
  margin-top: 30px;
  margin-bottom: 15px;
`;

const Subtitle = styled.h2`
  flex: 1;
`;

const StyledUserAvatar = styled(UserAvatar)`
  border: ${({ theme }) => theme.colors.borderXLight} solid thin;
`;

const LoadingUserAvatar = styled(AvatarContainer)`
  ${LoaderStyle}
  border: ${({ theme }) => theme.colors.borderXLight} solid thin;
`;

const BestSellingCount = styled.div`
  font-weight: bold;
  width: 22px;
`;

const BestSellingItemContainer = styled(FlexRow)`
  height: 40px;
`;

const BestSellingItem: React.FC<{
  user: User;
  volume: number;
  ranking: number;
  renderVolume: (volume: number) => string;
}> = ({
  user,
  volume,
  ranking,
  renderVolume,
}) => (
  <BestSellingItemContainer>
    <BestSellingCount>{ ranking }</BestSellingCount>
    <UserPopover user={ user }>
      <div tabIndex={ -1 }>
        <Link href={ getUserLink(user) } passHref>
          <a>
            <StyledUserAvatar user={ user } sizes={ { fallback: 34 } } />
          </a>
        </Link>
      </div>
    </UserPopover>
    <div style={ { marginLeft: 10 } }>
      <FlexRow>
        <UserPopover user={ user }>
          <div tabIndex={ -1 } style={ { fontWeight: 'bold', marginRight: 5 } }>
            <Link href={ getUserLink(user) } passHref>
              {user?.name || shortenAddress(user?.address) || '-'}
            </Link>
          </div>
        </UserPopover>
        <TzProfileBadge address={ user?.address } />
      </FlexRow>
      <TextLight style={ { marginTop: -2 } }>{ renderVolume(volume) }</TextLight>
    </div>
  </BestSellingItemContainer>
);

const bestSellingArtistsLoadingItems = Array.from({ length: 10 }).map((_, index) => index);

const BestSellingItemLoading = () => (
  <BestSellingItemContainer>
    <BestSellingCount>
      x
    </BestSellingCount>
    <LoadingUserAvatar $size={ 34 } />
    <div style={ { marginLeft: 10 } }>
      <TextLoader />
      <TextLoader style={ { width: 60, marginTop: 6 } } />
    </div>
  </BestSellingItemContainer>
);

const BestSellingArtistsList: React.FC<{
  data: (User & {
    volume: number;
  })[];
  isLoading: boolean;
}> = ({
  data: bestSellingArtists = [],
  isLoading,
}) => (
  <div>
    <SectionRow>
      <Subtitle>Best-selling artists</Subtitle>
    </SectionRow>
    <ArtistGrid>
      {
        isLoading ? bestSellingArtistsLoadingItems.map((k) => (
          <BestSellingItemLoading key={ k } />
        )) : bestSellingArtists.map(({ volume, ...user }, index) => (
          <BestSellingItem
            key={ `best.seller.${user.address}` }
            user={ user }
            volume={ volume }
            ranking={ index + 1 }
            renderVolume={ (vol) => `${formatAmount(vol, 0)} ${xtz} sold` }
          />
        ))
      }
    </ArtistGrid>
  </div>
);

const LargestCollectorsList: React.FC<{
  data: (User & {
    volume: number;
  })[];
  isLoading: boolean;
}> = ({
  data: largestCollectors = [],
  isLoading,
}) => (
  <div>
    <SectionRow>
      <Subtitle>Largest collectors</Subtitle>
    </SectionRow>
    <ArtistGrid>
      {
        isLoading ? bestSellingArtistsLoadingItems.map((k) => (
          <BestSellingItemLoading key={ k } />
        )) : largestCollectors.map(({ volume, ...user }, index) => (
          <BestSellingItem
            key={ `largest.collectors.${user.address}` }
            user={ user }
            volume={ volume }
            ranking={ index + 1 }
            renderVolume={ (vol) => `${formatAmount(vol, 0)} ${xtz} spent` }
          />
        ))
      }
    </ArtistGrid>
  </div>
);

const BestSellingObjktsList = ({ data, isLoading }) => (
  <>
    <SectionRow>
      <Subtitle>Best-selling objkts</Subtitle>
      <RightSection>
        <ObjktGridSelect />
      </RightSection>
    </SectionRow>
    <TokenList tokens={ data } isLoading={ isLoading } />
  </>
);

const TagGrid = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(5, 1fr);
  ${media.tablet`
    grid-template-columns: repeat(3, 1fr);  
  `}
  ${media.mobile`
    grid-template-columns: repeat(1, 1fr);  
  `}
`;

const Tag = styled.a<{ $loading?: boolean }>`
  background: ${({ theme }) => theme.colors.foreground};
  padding: 2px 10px;
  padding-top: 5px;
  margin: 3px;
  height: 44px;
`;

const TagLoader = styled.div`
  ${LoaderStyle}
  width: 100px;
  height: 44px;
  margin: 3px;
`;

const BestSellingTagItem = ({ tag, volume, ranking, renderVolume }) => (
  <FlexRow>
    <BestSellingCount>{ ranking }</BestSellingCount>
    <Link href={ `/tags/${tag}` } passHref>
      <Tag>
        <div style={ { fontWeight: 'bold', marginBottom: -3 } }>{tag}</div>
        <TextLight>
          { renderVolume(volume) }
        </TextLight>
      </Tag>
    </Link>
  </FlexRow>

);

const BestSellingTagItemLoading = ({ ranking }) => (
  <FlexRow>
    <BestSellingCount>{ ranking }</BestSellingCount>
    <TagLoader />
  </FlexRow>
);

export const BestSellingTagsList = ({
  data: tags = [],
  isLoading,
}) => (
  <>
    <SectionRow>
      <Subtitle>Best-selling tags</Subtitle>
    </SectionRow>
    <TagGrid>
      {
        isLoading ? bestSellingArtistsLoadingItems.map((k, index) => (
          <BestSellingTagItemLoading key={ k } ranking={ index + 1 } />
        )) : tags.map(({ tag, volume }, index) => (
          <BestSellingTagItem
            key={ `top.tags.${tag}` }
            tag={ tag }
            volume={ volume }
            ranking={ index + 1 }
            renderVolume={ (vol) => `${formatAmount(vol, 0)} ${xtz} sold` }
          />
        ))
      }
    </TagGrid>
  </>

);

const CollectorsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 15px;
  ${media.tablet`
    grid-template-columns: repeat(1, minmax(0, 1fr));
  `}
`;

const BestSellersMenu = () => {
  const temporality = useVolumeTemporality();
  return (
    <MenuNav
      label="Best-selling time period"
      sections={
        [
          {
            label: '1 hour',
            url: '/best-sellers',
            isActive: temporality === '1h',
          },
          {
            label: '1 day',
            url: '/best-sellers/1d',
            isActive: temporality === '1d',
          },
          {
            label: '1 week',
            url: '/best-sellers/1w',
            isActive: temporality === '1w',
          },
        ]
      }
    />
  );
};

const BestSellers = () => {
  const temporality = useVolumeTemporality();
  const { data, isLoading } = useBestSellersData(temporality, 16);
  const {
    objkts: bestSellingObjkts = [],
    artists: bestSellingArtists = [],
    collectors: largestCollectors = [],
    // tags: bestSellingTags = [],
  } = data;
  return (
    <>
      <div style={ { marginTop: 15 } }>
        <BestSellersMenu />
      </div>
      <CollectorsGrid>
        <BestSellingArtistsList data={ bestSellingArtists.slice(0, 10).filter(({ address }) => !!address) } isLoading={ isLoading } />
        <LargestCollectorsList data={ largestCollectors.slice(0, 10).filter(({ address }) => !!address) } isLoading={ isLoading } />
      </CollectorsGrid>
      {/*
      <BestSellingTagsList data={ bestSellingTags.slice(0, 10) } isLoading={ isLoading } />
      */}
      <BestSellingObjktsList data={ bestSellingObjkts.slice(0, 16) } isLoading={ isLoading } />
    </>
  );
};

const TipTextButton = styled(TextButton)`
  font-weight: bold;
`;

const numberOfColumns: BreakpointValues = {
  mobile: 1,
  tablet: 2,
  desktop: 4,
  tv: 4,
  fallback: 5,
};

const SponsorsGrid = styled(ArtistGrid) <{ $length: number }>`
  grid-template-columns: repeat(${numberOfColumns.fallback}, minmax(0, 1fr));
  ${media.tv`
    grid-template-columns: repeat(${numberOfColumns.tv}, minmax(0, 1fr));
  `};
  ${media.desktop`
    grid-template-columns: repeat(${numberOfColumns.desktop}, minmax(0, 1fr));
  `};
  ${media.tablet`
    grid-template-columns: repeat(${numberOfColumns.tablet}, minmax(0, 1fr));
  `};
  ${media.mobile`
    grid-template-columns: repeat(${numberOfColumns.mobile}, minmax(0, 1fr));
  `};
  /*
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-template-rows: repeat(${({ $length }) => $length / 4}, minmax(0, 1fr));
  ${media.tablet`
    grid-template-rows: repeat(2, minmax(0, 1fr));
  `}
  ${media.mobile`
    grid-template-rows: repeat(1, minmax(0, 1fr));
  `}
  */
`;

const Sponsors = () => {
  const { data: sponsors = [], isLoading } = useSponsors();
  const totalDonated = sumBy(sponsors, (o) => o.amount);
  const [showDonationModal, setShowDonationModal] = React.useState<boolean>(false);
  const {
    limitedRows,
    canShowMore,
    showMore,
  } = useShowMore<Sponsor>(sponsors);
  return (
    <div>
      <div style={ { marginTop: 10, marginBottom: 20 } }>
        This list celebrates everyone who donated to HIC.AF, either through tips when collecting or through a direct donation.
        <br />
        A huge thanks to everyone who contributed to make HIC.AF better. Your support means a lot!!
        {' '}
        <Emoji v="🥰" />
        <div>{ `In total, all sponsors have donated ${formatAmount(totalDonated, 2)} ${xtz} over the past ${calendar(new Date('2021-11-01T16:00:00')).replace(' ago', '')}.`}</div>
        <TipTextButton onClick={ () => setShowDonationModal(true) }>Donate to HIC.AF, join the list</TipTextButton>
        .
      </div>
      <SponsorsGrid $length={ limitedRows.length }>
        {
          isLoading ? bestSellingArtistsLoadingItems.map((k) => (
            <BestSellingItemLoading key={ k } />
          )) : (
            <>
              {
                limitedRows.map(({ amount, user }, index) => (
                  <BestSellingItem
                    key={ `best.seller.${user.address}` }
                    user={ user }
                    volume={ amount }
                    ranking={ index + 1 }
                    renderVolume={ (vol) => `${formatAmount(vol, 2)} ${xtz} donated` }
                  />
                ))
              }
            </>
          )
        }
      </SponsorsGrid>
      {
        canShowMore ? (
          <ShowMoreButton onClick={ showMore }>Show more</ShowMoreButton>
        ) : null
      }
      <DonationModal isOpen={ showDonationModal } handleClose={ () => setShowDonationModal(false) } />
    </div>
  );
};

const Page = () => {
  const section = useSection();
  return (
    <Layout>
      <Title>The smoothest NFT marketplace on Tezos</Title>
      <FeedMenu />
      {
        section === 'all' ? <LatestFeed />
          : section === 'sponsors' ? <Sponsors />
            : section === 'live' ? <Live />
              : section === 'followed' ? <Followed />
                : section === 'favorites' ? <Favorites />
                  : <BestSellers />
      }
    </Layout>
  );
};

export default Page;
