/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import React from 'react';
import uniqBy from 'lodash/uniqBy';
import { gql } from 'graphql-request';
import keyBy from 'lodash/keyBy';
import axios from 'axios';
import { v4 as uuid } from 'uuid';
// import { w3cwebsocket as W3CWebSocket } from 'websocket';
import LiveFilterButton, { useLiveFilterQueryParams } from './LiveFilterButton';
import { CountContainer, ObjktFilterRowContainer, RightSection } from './ObjktFilterRow';
import { transformToken, userFragment } from '~/graphql';
import { Operation } from '~/types';
import LiveFeedTable from './LiveFeedTable';
import { replaceIPFS } from '~/utils';
import { ADDRESSES } from '~/utils/addresses';
import useGraphqlClient from '~/hooks/useGraphqlClient';

const hex2ascii = (hexx: string): string => {
  const hex = hexx.toString();
  let str = '';
  for (let i = 0; i < hex.length; i += 2) { str += String.fromCharCode(parseInt(hex.substr(i, 2), 16)); }
  return str;
};

const getFeedData = async ({
  objktIds = [],
  userAddresses = [],
  client,
}: {
  objktIds: number[];
  userAddresses: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  client: any;
}) => {
  const query = gql`
    query liveFeedQuery(
      $objktIds: [bigint],
      $userAddresses: [String],
    ) {
      token(
        where: {
          id: {
            _in: $objktIds
          }
        }
        limit: 100
      ) {
        id
        title
        supply
        royalties
        mime
        display_uri
        thumbnail_uri
        artifact_uri
        timestamp
        __typename
        creator {
          ${userFragment}
        }
      }
      holder(
        where: {
          address: {
            _in: $userAddresses
          }
        }
      ) {
        ${userFragment}
      }
    }
  `;
  const {
    token = [],
    holder = [],
  } = await client(
    query,
    {
      objktIds,
      userAddresses,
    },
  );
  const objkts = keyBy(token.map(transformToken), 'id');
  const users = keyBy(holder, 'address');
  return { objkts, users };
};

const DIVIDER = 1000000;
const idx = (input, transformer) => { try { return transformer(input); } catch (err) { return null; } };
const getLink = (objktId) => `https://hic.af/o/${objktId}`;

const getOperations = async (msg): Promise<Operation[]> => Promise.all(
  msg.data?.map(async ({ parameters: params, hash, ...data }) => {
    const parameters = idx(params, JSON.parse);
    const type = parameters?.entrypoint;
    if (!type) return null;
    const date = new Date().toLocaleString();
    const output = {
      id: uuid(),
      hash,
      type,
      date,
    // parameters,
    // data,
    };
    if (type === 'swap') {
      return {
        ...output,
        initiatorAddress: parameters?.value?.args?.[0]?.args?.[0]?.string,
        objktId: +parameters?.value?.args?.[1]?.args?.[0]?.int,
        swapId: +data?.diffs?.[0]?.content?.key,
        quantity: +parameters?.value?.args?.[0]?.args?.[1]?.int,
        price: +parameters?.value?.args?.[1]?.args?.[1]?.args?.[1]?.int / DIVIDER,
        link: getLink(+parameters?.value?.args?.[1]?.args?.[0]?.int),
      } as Operation;
    }
    if (type === 'cancel_swap') {
      return {
        ...output,
        initiatorAddress: data?.sender?.address,
        swapId: +data?.diffs?.[0]?.content?.key,
        objktId: +data?.diffs?.[0]?.content?.value?.objkt_id,
        quantity: +data?.diffs?.[0]?.content?.value?.objkt_amount,
        link: getLink(+data?.diffs?.[0]?.content?.value?.objkt_id),
      } as Operation;
    }
    if (type === 'collect') {
      return {
        ...output,
        initiatorAddress: data?.sender?.address,
        swapId: +data?.diffs?.[0]?.content?.key,
        objktId: +data?.diffs?.[0]?.content?.value?.objkt_id,
        quantity: +data?.diffs?.[0]?.content?.value?.objkt_amount,
        price: +data.amount / DIVIDER,
        link: getLink(+data?.diffs?.[0]?.content?.value?.objkt_id),
      } as Operation;
    }
    if (type === 'transfer') {
      const initiatorAddress = data?.initiator?.address || data?.sender?.address;
      const recipientAddress = data?.parameter?.value?.[0]?.txs?.[0]?.to_;
      const realType = recipientAddress === ADDRESSES.burn ? 'burn' : type;
      if (realType !== 'burn') return null;
      return {
        ...output,
        type: realType,
        initiatorAddress,
        // recipientAddress,
        objktId: +data?.parameter?.value?.[0]?.txs?.[0]?.token_id,
        quantity: +data?.parameter?.value?.[0]?.txs?.[0]?.amount,
        link: getLink(+data?.parameter?.value?.[0]?.txs?.[0]?.token_id),
        parameters,
        data,
      } as Operation;
    }
    if (type === 'mint') {
      const ipfsLink = replaceIPFS(hex2ascii(data?.parameter?.value?.token_info?.['']));
      const { data: ipfsData } = await axios.get(ipfsLink);
      const objktId = +data.parameter.value.token_id;
      const objkt = {
        id: objktId,
        metadata: '',
        mimeType: ipfsData?.formats?.[0]?.mimeType,
        mime: ipfsData?.formats?.[0]?.mimeType,
        title: ipfsData?.name,
        description: ipfsData?.description,
        editions: 0,
        supply: 0,
        minPrice: 0,
        maxPrice: 0,
        isForSale: false,
        isFromBlockedWallet: false,
        isSecondarySale: false,
        creator: {
          address: ipfsData?.creators?.[0],
          name: '',
          description: '',
        },
        timestamp: new Date().toLocaleString(),
        assetUrl: replaceIPFS(ipfsData?.displayUri), // also has thumbnailUri
        imageUrl: replaceIPFS(ipfsData?.displayUri),
        artifact_uri: replaceIPFS(ipfsData?.displayUri),
        display_uri: replaceIPFS(ipfsData?.displayUri),
        preview_uri: replaceIPFS(ipfsData?.displayUri),
        creator_id: replaceIPFS(ipfsData?.displayUri),
        thumbnail_uri: replaceIPFS(ipfsData?.displayUri),
        trades: [],
        swaps: [],
        token_holders: [],
        token_tags: [],
        royalties: 0,
        minSwap: null,
        validSwaps: [],
        tags: ipfsData?.tags || [],
      };
      return {
        ...output,
        objkt,
        objktId,
        initiatorAddress: data.parameter.value.address,
        quantity: +data.parameter.value.amount,
        link: getLink(+data.parameter.value.token_id),
      } as Operation;
    }
    if ([
      'mint_OBJKT', // no objktId yet, this is the method called by the user, then mint is called internally
      'transaction',
      'update_operators',
      'transfer',
    ].includes(type)) {
      return null;
    }
    return {
      ...output,
      _parameters: parameters,
      _data: data,
    };
  }),
);

const LiveFeed = () => {
  const filter = useLiveFilterQueryParams();
  const [connection, setConnection] = React.useState<HubConnection | null>(null);
  const [feed, setFeed] = React.useState<Operation[]>([]);
  React.useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl('https://api.tzkt.io/v1/events')
      .withAutomaticReconnect()
      .build();
    setConnection(connect);
    // connection.onclose(init);
  }, []);
  const gqlClient = useGraphqlClient();
  React.useEffect(() => {
    if (connection && connection.state === 'Disconnected') {
      const init = async () => {
        await connection.start();
        await connection.invoke('SubscribeToOperations', {
          address: ADDRESSES.v1,
          types: 'transaction',
        });
        await connection.invoke('SubscribeToOperations', {
          address: ADDRESSES.v2,
          types: 'transaction',
        });
        await connection.invoke('SubscribeToOperations', {
          address: ADDRESSES.objkts,
          types: 'transaction',
        });
        connection.on('operations', async (msg) => {
          const ops = (await getOperations(msg)).filter(Boolean) || [];
          const objktIds = uniqBy(ops.map(({ objktId }) => objktId).filter(Boolean), (id) => +id);
          const userAddresses = uniqBy(
            ops.reduce((acc, { initiatorAddress, recipientAddress }) => [...acc, ...[initiatorAddress, recipientAddress]],
              [] as string[]).filter(Boolean),
            (address) => address,
          );
          const {
            objkts,
            users,
          } = await getFeedData({
            objktIds,
            userAddresses,
            client: gqlClient,
          });
          const opsWithData = ops.map((o) => ({
            ...o,
            initiator: users[o.initiatorAddress],
            recipient: users[o.recipientAddress],
            objkt: {
              ...(objkts[o.objktId] || {}),
              ...(o.objkt || {}),
            },
          }));
          setFeed((f) => [...opsWithData.reverse(), ...f]);
        });
      };
      init();
    }
  }, [connection]);
  const filteredFeed = React.useMemo(() => feed
    .filter(({ type }) => (filter.activity.length === 0 ? true : filter.activity.includes(type)))
    .filter(({ initiatorAddress, recipientAddress }) => (filter.wallet.length === 0 ? true
      : (filter.wallet.includes(initiatorAddress) || filter.wallet.includes(recipientAddress))))
    .slice(0, 100),
  [JSON.stringify(feed), JSON.stringify(filter)]);
  /*
  React.useEffect(() => {
    const client = new W3CWebSocket('ws://51.75.194.124:8080');
    client.onopen = () => {
      console.log('WS Client Connected');
    };
    client.onmessage = (e) => {
      const message = JSON.parse(e.data);
      const types = message.contents.map(({ parameters }) => parameters.entrypoint);
      console.log('WS message', types);
    };
  }, []);
  */
  return (
    <>
      <ObjktFilterRowContainer>
        <CountContainer>
          Displaying the 100 latest HEN events as they arrive on the blockchain.
        </CountContainer>
        <RightSection>
          <LiveFilterButton />
        </RightSection>
      </ObjktFilterRowContainer>
      <LiveFeedTable operations={ filteredFeed.slice(0, 100) } />
    </>
  );
};

export default LiveFeed;
